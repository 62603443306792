<template>
  <v-responsive>
    <SubMenuStoreMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuStoreMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuStore></SubMenuStore>
          
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>


        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>



          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>



          <v-responsive class="mt-10">
              <h3>로또전문가가 권장하는 로또 구입요령</h3>
              <p >로또 6/45는 (주)동행복권과 판매점 계약을 체결한 로또 단말기가 설치된 <a href="https://dhlottery.co.kr/store.do?method=sellerInfo645" target="_blank">판매점</a>(전국의 편의점, 복권방 및 가판대 등)에서 구매할 수 있습니다.
              </p>
          </v-responsive>




          <v-card
            style="margin:  auto; border: 1px solid #eee"
            flat
          >
            <v-list two-line>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong> 한번호대에 4개이상은 피하라.</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                      한번호대에서 4개이상 출현한 빈도는 5%미만이고 5개출현은 2%미만입니다.<br>
                      예) 3.11.13.15.16.39또는 3.30.31.33.35.37같은 4개이상말입니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong> 4연번은피하라.</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                      연속해서 4연번이 나온경우는 전세계적으로도 드문 일입니다.<br>
또한 우리나라에선 3연번이 5번정도 나왔는데 우리나라만 제외하고 전세계적으론 2%도 안된다고합니다.<br>
예) 4.11.12.13.14.35이런 조합말입니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong> 같은 끝수 3개이상은 피하라.</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                      같은끝수가 3개이상이 20%밖에 안된다고합니다. 그러니 같은끝수는 2개정도가 적당합니다.<br>
예) 3.13.23.35.36.40 이렇게 끝수가 3이3번들어간경우 말입니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>2개의 쌍연번은 피하라.</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     그냥 일반적인 연번의 출현빈도는 80%정도로 추천할만하나 쌍연번의 출현빈도는 낮다고합니다.<br>

예) 3.4.15.18.33.34 이렇게 3.4와 33.34같은경우 말입니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      낮은수조합또는 높은수조합은피하라.                      
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     낮은수라함은 23을 기준으로 예) 2.5.16.17.20.22.또는 25.27.29.34.37.44이렇게 높거나 낮은경우를말함.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      연번호를 활용하라.                     
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     예) 33.34.11.15.00.41.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      가장 중요한대목입니다. 로또에 많은돈 투자하지마세요.                    
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     로또에 가능조합은 1/8145060의 입니다.

이걸 금액으로 환산하면 약 81억원어치의 복권을 구입해야한다는 이론적 계산이나옵니다. 즉. 여러분이 1만원어치를하든

10만원어치를하든 또는 100만원 1000만원어치를해도 81억원에 비하면 매우 미미한 차이입니다. 100만원어치면 여러가지숫자로 할만큼

했다고 생각할수있으나 그건 위험한 생각입니다.

다시말해 1만원이든 1000만원이든 새발에 피란말입니다. 1000원 1게임을해도 1등에 당첨되려면 되고 1000만원어치를 해도

5등 10개하는 사람도있습니다.(TV보도인용자료) 그러니 1~2만원정도가 적당하지 않을까 생각합니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>



              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      되도록 많은 번호를 선택하라.                 
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     로또 초보분들의 숫자선택을보면 1만원 1게임에 최대.30개의 숫자를 넣을수있는데 대게 15~20개의 숫자를 선택하여

중복되게 고르는 경향이 있습니다. 숫자는 되도록 골고루 사용하는편이 그렇지 않은편보다 확률상 높습니다.

고수분들은 어떤 믿음을가지고 고정수를 활용하나 이는 위험합니다. 모 아니면 도이기 때문입니다.

고정수란? 예) 2.13.15.22.26.38 / 2.14.15.22.27.39 /2.11.15.22.28.40 ..그럼 2.15.22가 고정수입니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      마킹용지에 일정한 도형이나 라인은 피하라.            
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     마킹용지에 마킹을할때 일정한 도형이나 동일한 라인에 마킹을하는건 바람직하지 않습니다.

실제로 이렇게 일정한 모양이나 라인이되게 번호를 선택하는사람이 많다고하니 당첨이되어도 당첨금은 매우 낮아집니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      당연한 얘기입니다만 1.2.3.4.5.6.이런 엽기적인 번호선택은 절대금물.         
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     
당연한 얘기지만 어처구니 없게도 이런 번호를 선택한 사람이 한회차에도 수만장씩 나온다고합니다.

(국민은행 발표자료인용)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                      근거없는 믿음에 많은 투자하지말자.   
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     꿈을 잘꾸었다...누가 추첨할땐 몇번이 꼭나온다...이런식으로 근거없는 믿음을 믿고 많은돈 투자하지맙시다.

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                     되도록 2홀4짝,4홀2짝,3홀3짝이되게 하라.
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                     당첨숫자들의 홀,짝수 분포를보면 위와같이 3가지의 홀짝 배열이 80%이상이라고 합니다.

그러니 올 짝수나 올홀수는 확률이 매우낮죠.

예) 3.9.11.15.23.33(올 홀수) 2.14.16.22.26.38(올 짝수) 피하라..

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                     사람마다 선호하는 번호가있고 선호하는 패턴이있어서 무의식중에 반복되고 중복된다고합니다.
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                    물론 특정한 번호나 패턴을 꾸준히 밀고나가는것도 좋지만...가끔은 자동선택도 활용하여 행운을 잡을수도있다.


                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                     이전 회차에서 중복되는 3개이상의 숫자는 피하라.
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                    확률적으로 낮다고합니다.

예) 49회차 1.2.3.4.5.6.이 당첨이였다면 이중 3개이상은 가져가지말자.. 1~2개정도가 적당하다고보나 안나올수도있음.


                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:14px;"><strong>
                     일정한 규칙을가진 숫자는 절대피하라.
                      </strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
          예) 5.10.15.20.25.30. 또는 1.11.21.31.41...또는 5.0.8.16.32..(확률이 낮고 당첨되어도 배당금이 매우낮아진다함.)


                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>



            </v-list>
          </v-card>




              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>





        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Store",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "판매점 분석" },
      { text: "로또 구입요령" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),
};
</script>
<style></style>
